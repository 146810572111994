<template>
  <v-container id="van" fluid tag="section">
    <v-snackbar v-model="sameProductTotalStock" :timeout="4000" top>
      <span>Can't add More than Variant Quantity</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="sameProductTotalStock = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="moreThanTotalStockEdit" :timeout="4000" top>
      <span>Can't add More than Variant Quantity</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="moreThanTotalStockEdit = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="sameProduct" :timeout="4000" top>
      <span>Already Add this Product in Van</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="sameProduct = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-data-table
      :headers="columns"
      :items="vanStock"
      item-key="_id"
      class="elevation-1"
      :search="search"
      :custom-filter="filterOnlyCapsText"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" class="text-right">
              <v-btn
                type="button"
                color="primary"
                class="mr-0"
                @click="addInventory"
              >
                Add Inventory
              </v-btn>
            </v-col>
            <v-dialog
              v-model="dialog"
              persistent
              max-width="600px"
              :retain-focus="false"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">Add Inventory</span>
                </v-card-title>
                <v-form ref="addInventoryForm">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            :items="allProducts"
                            label="Select Products"
                            item-text="name"
                            item-value="_id"
                            v-model="productId"
                            @change="getProductsVariants"
                            :rules="inputRule"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            :items="productsVariants"
                            label="Select Product Variants"
                            item-text="variant_quantity_per_pack"
                            item-value="_id"
                            v-model="variantId"
                            @change="getVariantQuantity"
                            :rules="inputRule"
                          >
                            <template slot="selection" slot-scope="data">
                              {{
                                data.item.variant_quantity_per_pack +
                                "x " +
                                data.item.variant_volume_value +
                                " " +
                                data.item.variant_volume_type +
                                " " +
                                data.item.variant_type
                              }}
                            </template>
                            <template slot="item" slot-scope="data">
                              {{
                                data.item.variant_quantity_per_pack +
                                "x " +
                                data.item.variant_volume_value +
                                " " +
                                data.item.variant_volume_type +
                                " " +
                                data.item.variant_type
                              }}
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            type="number"
                            min="1"
                            step="1"
                            class="purple-input"
                            label="Available Stock"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            v-model="variantStockUseinVan"
                            @keyup="notMoreThanTotalVariantQuantity"
                            :rules="inputRule"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeAddInventory"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="sameProductTotalStock"
                      @click="submitInventory"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.variant_quantity`]="{ item }">
        <v-chip
          :color="getColor(item.variant_quantity)"
          dark
          style="margin-right: 10px"
        >
          {{ item.variant_quantity }}
        </v-chip>
        <v-btn small @click="topupInventory(item)">topup</v-btn>
        <v-dialog
          v-model="showTopupInventory"
          persistent
          max-width="400px"
          :retain-focus="false"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Topup Inventory</span>
            </v-card-title>
            <v-form ref="topupInventoryForm">
              <v-card-text>
                <label>Available stock: {{ availableQuantity }}</label>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        type="number"
                        min="1"
                        step="1"
                        class="purple-input"
                        label="Topup Inventory"
                        v-model="addTopupInventory"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        @keyup="updateTopupInventory"
                        :rules="inputRule"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary darken-1"
                  text
                  @click="closeTopupInventory"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="submitTopupInventory"
                  :disabled="sameProductTotalStock"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="mr-2" @click="editInventory(item)">mdi-pencil</v-icon>
        <v-dialog
          v-model="editDialog"
          persistent
          max-width="600px"
          :retain-focus="false"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Edit Inventory</span>
            </v-card-title>
            <v-form ref="editInventoryForm">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        label="Select Products"
                        v-model="editProdcutName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        label="Select Product Variants"
                        v-model="editVariantData"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        class="purple-input"
                        label="Available Stock"
                        v-model="editVariantStockUseinVan"
                        @keyup="editNotMoreThanTotalVariantQuantity"
                        :rules="inputRule"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary darken-1"
                  text
                  @click="closeEditInventory"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="submitEditInventory"
                  :disabled="moreThanTotalStockEdit"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.variant_image`]="{ item }">
        <v-img
          height="50px"
          width="50px"
          v-if="item.variant_image != null"
          :src="imageUrl + item.variant_image"
          :lazy-src="imageUrl + item.variant_image"
        />
      </template>
    </v-data-table>
    <div class="custom_pagination_design">
      <span>
        Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
        <b>{{ totalRecord }}</b> Records
      </span>
      <v-pagination
        class="float-right"
        v-model="page"
        :length="totalPages"
        total-visible="6"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </div>
    <v-row class="mt-3">
      <v-col cols="12" class="text-left">
        <router-link :to="{ name: 'Vans' }">
          <v-btn type="button" color="primary" class="mr-0">Back</v-btn>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      vanStock: [],
      search: "",
      loading: true,
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      imageUrl: process.env.VUE_APP_Image_Link,
      dialog: false,
      allProducts: [],
      productId: "",
      productsVariants: [],
      variantId: "",
      variantStockUseinVan: "",
      sameProductTotalStock: false,
      inputRule: [(v) => !!v || "Field is required"],
      productVanStock: [],
      sameProduct: false,
      editDialog: false,
      editProdcutId: "",
      editProdcutName: "",
      editVariantData: "",
      editVariantId: "",
      editVariantStockUseinVan: "",
      editProductsVariants: [],
      editVanStock: [],
      moreThanTotalStockEdit: false,
      showTopupInventory: false,
      addTopupInventory: "",
      availableQuantity: "",
      currentQuantity: "",
      topupProductsVariants: "",
      topupVanStock: "",
      topupVariantId: "",
      topProductId: "",
      requiredMinimumVanQuantity: "",
    };
  },
  computed: {
    columns() {
      return [
        {
          sortable: false,
          text: "Actions",
          value: "actions",
          fixed: true,
          sortable: false,
        },
        { sortable: false, text: "Product Name", value: "product_name" },
        {
          sortable: false,
          text: "Quantity per Pack",
          value: "variant_quantity_per_pack",
        },
        { sortable: false, text: "Type", value: "variant_type" },
        { sortable: false, text: "Volume value", value: "variant_volume_type" },
        { sortable: false, text: "Volume Type", value: "variant_volume_value" },
        {
          sortable: false,
          text: "Actual Price",
          value: "variant_actual_price",
        },
        {
          sortable: false,
          text: "Retail Price",
          value: "variant_retail_price",
        },
        {
          sortable: false,
          text: "Van Quantity",
          value: "variant_quantity",
          width: "150px",
        },
        { sortable: false, text: "Image", value: "variant_image" },
      ];
    },
  },
  methods: {
    getVanProducts() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "van/product/" +
            this.id +
            "?page=" +
            this.page
        )
        .then((response) => {
          if (response.status == 200) {
            this.vanStock = response.data.vanStock.data;
            this.loading = false;
            this.totalPages = response.data.vanStock.last_page;
            this.startRecord = response.data.vanStock.from;
            this.endRecord = response.data.vanStock.to;
            this.totalRecord = response.data.vanStock.total;
            this.numbers = [];
            for (
              let num = response.data.vanStock.from;
              num <= response.data.vanStock.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addInventory() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "product/getall")
        .then((response) => {
          if (response.status == 200) {
            this.allProducts = response.data.products;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.dialog = true;
    },
    closeAddInventory() {
      this.dialog = false;
      this.productId = null;
      this.variantId = null;
      this.variantStockUseinVan = null;
    },
    editInventory(data) {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + "product/edit/" + data.product_id
        )
        .then((response) => {
          if (response.status == 200) {
            this.editProductsVariants = response.data.product.product_variants;
            this.editVanStock = response.data.product.van_product_stock;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.editProdcutName = data.product_name;
      this.editProdcutId = data.product_id;
      this.editVariantData =
        data.variant_quantity_per_pack + " " + data.variant_type;
      this.editVariantId = data.variant_id;
      this.editVariantStockUseinVan = data.variant_quantity;
      this.editDialog = true;
    },
    closeEditInventory() {
      this.editDialog = false;
    },
    editNotMoreThanTotalVariantQuantity() {
      let totalVanStock = 0;
      this.editVanStock.forEach((van) => {
        if (van.variantId == this.editVariantId) {
          if (van.vanId != this.id) {
            totalVanStock += van.variantStock;
          }
        }
      });
      let variantQuantity = "";
      this.editProductsVariants.forEach((variant) => {
        if (variant._id == this.editVariantId) {
          variantQuantity = variant.variant_quantity;
        }
      });
      let useStock = +totalVanStock + +this.editVariantStockUseinVan;
      if (parseInt(variantQuantity) >= parseInt(useStock)) {
        this.moreThanTotalStockEdit = false;
      } else {
        this.moreThanTotalStockEdit = true;
      }
    },
    topupInventory(data) {
      this.topProductId = data.product_id;
      this.topupVariantId = data.variant_id;
      this.currentQuantity = data.variant_quantity;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + "product/edit/" + data.product_id
        )
        .then((response) => {
          if (response.status == 200) {
            let totalVanStock = 0;
            this.topupProductsVariants = response.data.product.product_variants;
            this.topupVanStock = response.data.product.van_product_stock;
            this.topupVanStock.forEach((van) => {
              if (van.variantId == data.variant_id) {
                totalVanStock += van.variantStock;
              }
            });
            this.topupProductsVariants.forEach((variant) => {
              if (variant._id == data.variant_id) {
                // this.variantData.push(variant);
                this.availableQuantity =
                  variant.variant_quantity - totalVanStock;
              }
            });

            this.showTopupInventory = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      // axios
      //   .get(
      //     process.env.VUE_APP_API_BASE_URL + "product/edit/" + data.product_id
      //   )
      //   .then((response) => {
      //     if (response.status == 200) {
      //       this.editProductsVariants = response.data.product.product_variants;
      //       this.editVanStock = response.data.product.van_product_stock;
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      // this.topupProdcutName = data.product_name;
      // this.topupProdcutId = data.product_id;
      // this.topupVariantData =
      //   data.variant_quantity_per_pack + " " + data.variant_type;
      // this.topupVariantId = data.variant_id;
      // this.topupVariantStockUseinVan = data.variant_quantity;
    },
    updateTopupInventory() {
      console.log(this.availableQuantity);
      console.log(this.addTopupInventory);
      if (this.availableQuantity == 0) {
        this.sameProductTotalStock = true;
      } else if (this.availableQuantity >= this.addTopupInventory) {
        this.sameProductTotalStock = false;
      } else {
        this.sameProductTotalStock = true;
      }
    },
    submitTopupInventory() {
      this.sameProductTotalStock = false;
      if (this.$refs.topupInventoryForm.validate()) {
        let updateInventory = +this.currentQuantity + +this.addTopupInventory;
        let updateVanInventory = [];
        this.topupVanStock.forEach((stock) => {
          let newVariantStock = "";
          let vanId = "";
          let variantId = "";
          if (
            stock.vanId == this.id &&
            stock.variantId == this.topupVariantId
          ) {
            vanId = this.id;
            variantId = this.topupVariantId;
            newVariantStock = updateInventory;
          } else {
            vanId = stock.vanId;
            variantId = stock.variantId;
            newVariantStock = stock.variantStock;
          }
          let update = {
            vanId: vanId,
            variantId: variantId,
            variantStock: newVariantStock,
          };
          updateVanInventory.push(update);
        });
        let data = {
          productId: this.topProductId,
          van_stock: updateVanInventory,
        };
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + "product/updateVanInventory",
            data
          )
          .then((response) => {
            if (response.status == 200) {
              this.showTopupInventory = false;
              this.topProductId = null;
              this.topupVariantId = null;
              this.addTopupInventory = null;
              this.getVanProducts();
            }
          })
          .catch((error) => {
            this.dialog = false;
            console.log(error);
          });
      }
    },
    submitEditInventory() {
      if (this.$refs.editInventoryForm.validate()) {
        let updateVanInventory = [];
        this.editVanStock.forEach((stock) => {
          let newVariantStock = "";
          let vanId = "";
          let variantId = "";
          if (stock.vanId == this.id && stock.variantId == this.editVariantId) {
            vanId = this.id;
            variantId = this.editVariantId;
            newVariantStock = this.editVariantStockUseinVan;
          } else {
            vanId = stock.vanId;
            variantId = stock.variantId;
            newVariantStock = stock.variantStock;
          }
          let update = {
            vanId: vanId,
            variantId: variantId,
            variantStock: newVariantStock,
          };
          updateVanInventory.push(update);
        });
        let data = {
          productId: this.editProdcutId,
          van_stock: updateVanInventory,
        };
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + "product/updateVanInventory",
            data
          )
          .then((response) => {
            if (response.status == 200) {
              this.editDialog = false;
              this.editProdcutName = null;
              this.editVariantData = null;
              this.editVariantStockUseinVan = null;
              this.getVanProducts();
            }
          })
          .catch((error) => {
            this.dialog = false;
            console.log(error);
          });
      }
    },
    getProductsVariants() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + "product/edit/" + this.productId
        )
        .then((response) => {
          if (response.status == 200) {
            this.productsVariants = response.data.product.product_variants;
            this.productVanStock = response.data.product.van_product_stock;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVariantQuantity() {
      let totalVanStock = 0;
      this.productVanStock.forEach((van) => {
        if (van.variantId == this.variantId) {
          totalVanStock += van.variantStock;
        }
      });
      this.productsVariants.forEach((variant) => {
        if (variant._id == this.variantId) {
          // this.variantData.push(variant);
          this.variantStockUseinVan = variant.variant_quantity - totalVanStock;
        }
      });
    },
    notMoreThanTotalVariantQuantity() {
      let variantQuantity = "";
      this.productsVariants.forEach((variant) => {
        if (variant._id == this.variantId) {
          variantQuantity = variant.variant_quantity;
        }
      });
      if (parseInt(variantQuantity) >= this.variantStockUseinVan) {
        this.sameProductTotalStock = false;
      } else {
        this.currentChangeVariantQuantity = variantQuantity;
        this.sameProductTotalStock = true;
      }
    },
    submitInventory() {
      if (this.$refs.addInventoryForm.validate()) {
        let addInventory = null;
        const BreakError = {};
        // same prodcut not add
        if (this.productVanStock.length != 0) {
          this.productVanStock.forEach((stock) => {
            if (stock.vanId == this.id && stock.variantId == this.variantId) {
              this.sameProduct = true;
              this.dialog = false;
              this.getVanProducts();
              throw BreakError;
            } else {
              addInventory = {
                vanId: this.id,
                variantId: this.variantId,
                variantStock: this.variantStockUseinVan,
              };
            }
          });
        } else {
          addInventory = {
            vanId: this.id,
            variantId: this.variantId,
            variantStock: this.variantStockUseinVan,
          };
        }
        this.productVanStock.push(addInventory);
        let data = {
          productId: this.productId,
          van_stock: this.productVanStock,
        };
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + "product/updateVanInventory",
            data
          )
          .then((response) => {
            if (response.status == 200) {
              this.dialog = false;
              this.productId = null;
              this.variantId = null;
              this.variantStockUseinVan = null;
              this.getVanProducts();
            }
          })
          .catch((error) => {
            this.dialog = false;
            console.log(error);
          });
      }
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
    handlePageChange(value) {
      this.page = value;
      this.getProductStock();
    },
    getColor(quantity) {
      let neartoTopup = this.requiredMinimumVanQuantity + 15;
      if (quantity <= this.requiredMinimumVanQuantity) return "red";
      else if (quantity <= neartoTopup) return "orange";
      else return "green";
    },
    getMinimumInventoryRequired() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "minimumVanInventoryRequired")
        .then((response) => {
          if (response.status == 200) {
            this.requiredMinimumVanQuantity =
              response.data.Data.minimumQuantity;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeTopupInventory() {
      this.showTopupInventory = false;
    },
  },
  mounted() {
    this.getVanProducts();
    this.getMinimumInventoryRequired();
  },
};
</script>
